// CampaignDescription.tsx
import React, { useState } from 'react';
import WebsiteCollector from './WebsiteCollector';

interface Props {
  description: string;
  setDescription: (value: string) => void;
  selectedDate: string;
  setSelectedDate: (value: string) => void;

}

const CampaignDescription: React.FC<Props> = ({ description, setDescription, selectedDate, setSelectedDate }) => {
  const handleDateChange = (event: any) => {
    setSelectedDate(event.target.value);
  };
  const [websiteList,setWebsiteList] = useState<any[]>([{
    "type": "social_media",
    "website": "YouTube",
    "url": ""
  }]);

  return (
    <div className="fadeIn">
      <p className='question'>  Campaign Description</p>
      <p style={{ textAlign: 'start', color: 'gray', fontSize: 17,marginTop: -15 }}>Summarize your ad, key goals (e.g., brand awareness, sales), and any critical details influencers need to know, such as messaging or target audience.</p>
      <div className="flex flex-col items-center justify-center ">
        <textarea
          className="w-[100%] h-32 p-2 border border-gray-300 rounded-md resize-none focus:outline-none focus:border-blue-500"
          placeholder="Enter campaign description here..."
          //    value={text}
          onChange={(event: any) => {
            setDescription(event.target.value);
          }}
        />
        
        <div style={{display:'flex',flexDirection:'column', alignItems:'flex-start',width:'100%',padding: 0,marginTop: 20}}>
        <p className='question'>  Video Description</p>
        <p style={{ textAlign: 'start', color: 'gray', fontSize: 17,marginTop: -15 }}>Provide key info for the video description, promo codes, or hashtags.</p>
          </div>
        
    <textarea
          className="w-[100%] h-32 p-2 border border-gray-300 rounded-md resize-none focus:outline-none focus:border-blue-500"
          placeholder="This will be added under the campaign video / image"
          //    value={text}
          onChange={(event: any) => {
            setDescription(event.target.value);
          }}
        />

      </div>


      <div className="flex flex-col my-[3%]">
          <p className='question'>  Select start Date </p>
        <input
          type="date"
          id="datepicker"
          min={new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
          className="w-64 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>

      <WebsiteCollector WebsiteList={websiteList} setWebsiteList={setWebsiteList} setPages={()=>{}} />

    </div>
  );
};

export default CampaignDescription;
