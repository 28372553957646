import { MdCampaign, MdOutlineAttachMoney } from "react-icons/md";
import SingleCardContent from "../../../component/singleCardContent/SingleCardContent";
import { orderProp } from "./prop/prop.order";
import { GiTakeMyMoney } from "react-icons/gi";
import { GrFormView } from "react-icons/gr";

import "./styles/style.mobile.orders.css";
import { useEffect, useState } from "react";
import { addPayment, calculateBudget, calculateTotalProgress, getCampaignDetail } from "./controller.campaign";
import { Button, Tab } from "@mui/material";
import Table from "../../../component/table/table";
import { creatorColumnData, creatorColumnDataTelegram } from "./data/column.creators";
import { creatorRowData, getCreatorRowData } from "./data/data.creators";
import CampaignDetailStatus from './campaignDetailStatusHeader';
import { RiWalletFill } from "react-icons/ri";
import ProgressBar from "../../../component/progressBar/progressBar";
import axios from "axios";
import { url } from "../../../util/constant";
import PaymentSection from "../createCampaign/components/PaymentSection";
import TotalPaymentSection from "./payment_section/payment_section";
import axiosInstance from "../../../axiosConfig";
import ChatBox from "../../../component/chat_box/chatBox";

export default function DetailCampaign(order: orderProp) {
  const [campaignDetail, setCampaignDetail] = useState<any>({});
  const [creatorData, setCreatorData] = useState<any>([]);
  const [status,setStatus] = useState<string>("");
  const [totalBudget,setTotalBudget] = useState<number>(0);
  const [paymentInfo, setPaymentInfo] = useState<any>({});
  const [userId, setUserId] = useState<number>(0);

  useEffect(() => {

    let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "");
    setUserId(userInfo.body.id);

    getCampaignDetail(order.id)
      .then((res: any) => {
        setCampaignDetail(res[0]);
        console.log("redts", res);
      })
      .catch((err) => {
        console.log(err);
      });

   function getCampaignStatus(){
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

    return new Promise((resolve, reject) => {
        axiosInstance.get(`${url}/campaign/campaign/status/${order.id}`,{
            headers: {
                "Authorization": "Bearer " + userInfo   
            }
        })
          .then(function (response) {
        
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        });
  
    }

    getCampaignStatus().then((res: any) => {
      console.log("Campaign Status: ", JSON.stringify(res.data));
      
      setStatus(res.data.status);
    }
    );


    async function getCreatorList() {
      var creatorD: any = await getCreatorRowData(order.id);
      // add an id to the data using index of the array
      creatorD.map((data: any, index: number) => {
        data["id"] = index + 1;
        data["Detail"] =
          data.Status === "Requested"
            ? `${index + 1}.false`
            : `${index + 1}.true  `;
      });

      console.log("Creator Data: ", creatorD);

      setCreatorData(creatorD);
    }

    calculateBudget(order.id).then((res:any) => {
      console.log("TOtal BUdget: ", res);
        setTotalBudget(res[0].Budget);
    }).catch((err)=>{

    })

    getCreatorList();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        marginBottom: "10px",
        width: "100%",
        flexDirection: "column",
        backgroundColor: '#F8F8F8',
        flexWrap: "wrap"
      }}
    >
      <CampaignDetailStatus status={status} />
      <span style={{height: status == "Payment_Verification"? 50 : 130,width: '100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>

      

      </span>
      { status == "Payment_Verification" ?
        <div style={{display: 'flex', flexDirection:'column',width: '85%',margin: 'auto'}}> 
      <TotalPaymentSection setPaymentInfo={setPaymentInfo} campaignId={3} totalPrice={totalBudget} />
     
      <Button variant="contained" color="primary" style={{width: 200,marginTop: 20,backgroundColor:'#090D25'}}
       onClick={() => {
          let paymentInfoBody: any = {
            campaignId: order.id,
            paymentStatus: "Payment_Verified",
            paymentAmount: totalBudget,
            transactionNumber: paymentInfo.transactionNumber,
            paymentDate: new Date().toISOString().slice(0, 10)
          };
        addPayment(paymentInfoBody).then((res)=>  {
          // refersh page after payment
          window.location.reload();
        }).catch((err)=>{

        })
       }}>
        Verify Payment 
        </Button>
        
        </div> 
        :
        <>
          
          <div style={{width: '85%',alignSelf:'center'}}>
            
              {/* <h1> {campaignDetail.socialMediaId}</h1> */}
            <Table columns={campaignDetail.socialMediaId == 3? creatorColumnDataTelegram : creatorColumnData} data={creatorData} />
          </div>
      
      
      <div className="campaignDetails" style={{background:'transparent'}}>
        <div className="order_video_section">
        {campaignDetail?.shortVideoUrl ? (
  campaignDetail?.shortVideoUrl.includes("youtube.com") ? (
    <iframe
      width="83%"
      height="615"
      src={campaignDetail?.shortVideoUrl
        .replace("http://", "https://")
        .replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/")}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      style={{ border: 'none' }} 
    ></iframe>
  ) : (
    <img
      src={campaignDetail?.shortVideoUrl}
      alt="Campaign Detail"
      style={{ maxWidth: "83%", height: "auto", display: "block" }} 
    />
  )
) : null}
          <p>{campaignDetail?.contentDescription}</p>
          
        </div>
        <div className="video_detail">
        <div className="card-campaign-detail">
          <RiWalletFill style={{color: '#090D25', fontSize: 60}} />
          <h1>{ status == "Payment_Verification" || status == "production"? parseInt(totalBudget.toString()).toLocaleString() +" Birr" :  "Required Budget will be avialbe"}</h1>
        </div>
        <div className="card-campaign-detail" >
          <ProgressBar progress={calculateTotalProgress(200,0)} progressBarWidth={190} progressBarHeight={50} />
          <h1>Campaign Progress</h1>
        </div>
        </div>
      </div>
      {
        status == "production" &&
        <ChatBox campaignId={campaignDetail.id} userId={userId} /> 

      }
        </>
        }
    
    </div>
  );
}
