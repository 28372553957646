import { Router, Routes, Route, Link, useParams } from "react-router-dom";
import CreateAccount from "../create_account/create_account";
import Home from "../home/home";
import HowItWorks from "../how_it_works/how_it_works";
import DashboardHome from "./home/dashboardHome";
import "./style.mainDashboard.css";
import "./style.mobile.mainDashboard.css";
import Orders from "./orders/orders";
import logo from "../../component/assets/images/zeter adv white logo1.png";
import { AiFillHome } from "react-icons/ai";
import { AiOutlineOrderedList } from "react-icons/ai";
import { AiFillProfile } from "react-icons/ai";
import { RiAccountCircleFill, RiTelegramFill, RiYoutubeFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { mainDashboardProp } from "./prop.mainDashboard";
import Order from "./orders/order";
import Profile from "./profile/profile";
import { userProp } from "../auth/prop.creator";
import { getUserInfo, getUserType } from "../../util/controller";
import SuccessToast from "../../component/toast/success";
import ListCampaign from "../dashboard_business/campaign/listCampaigns";
import { Md10K, MdChat, MdHowToReg, MdOutlineWifiChannel, MdPayment, MdWifiChannel, MdYoutubeSearchedFor } from "react-icons/md";
import { relative } from "path";
import Payments from "./payment/payments";
import Tutorial from "./tutorial/tutorial";
import Telegram from "./telegram/telegram_list/telegram";
import { FaYoutube } from "react-icons/fa6";
import YoutubeAccounts from "./youtube/youtubeAccountList";
import { CiMenuKebab } from "react-icons/ci";
import axios from "axios";
import AddTelegramChannel from "./telegram/connetTelegram/AddTelegramChannel";
import AddYoutubeChannel from "./youtube/manualConnect/addYoutubeChannel";

export default function MainDashboard(screen: mainDashboardProp) {
  const [title, setTitle] = useState<string>("home");
  const [screenS, setScreenS] = useState<string>(screen.screen);
  const [bodyInfo, setBodyInfo] = useState<userProp>(getUserInfo());
  const [successMessage, showSuccessMessage] = useState(false);
  const [userType, setUserType] = useState<string>(getUserType());
  const [headerMenuEnabled,setHeadeMenuEnabled] = useState<boolean>(false);
  const [immm,setImm] = useState<string>("https://4.imimg.com/data4/PL/LB/ANDROID-57246875/product-500x500.jpeg");
  const { id }: any = useParams();
  useEffect(() => {
    showSuccessMessage(true);
    setTimeout(() => {
      showSuccessMessage(false);
    }, 2000);

    // async function ttt(){
    //   const response = await fetch('http://localhost:3001/telegram/get-telegram-images?socialMediaAccountId=2'); // Make sure the URL matches your NestJS endpoint
    //   const blob = await response.blob();
    //   const imageUrl = URL.createObjectURL(blob);
    //   setImm(imageUrl);
    // }
   
    // ttt();

    // axios.get("http://localhost:3001/telegram/get-telegram-images?socialMediaAccountId=2").then((response) => {
    //   console.log("User Info: ", response.data);
    //   const base64Image = response.data.data.toString('base64');
    //   console.log("ALLss : ", base64Image);
      
    //   // Determine MIME type (update this if you know the specific type)
    //   const mimeType = 'image/png'; // Replace with 'image/png', 'image/gif', etc. as needed
      
    //   // Construct the data URL
    //   const url = `data:${mimeType};base64,${base64Image}`;
    //   console.log("ALL : ", url);
    //   setImm(url);
    // }).catch((err) => {
    //   console.error(err);
    // });
  }, []);


  const handleClose = () => {
    showSuccessMessage(false);
  };
  return (
    <div className={"mainDashboardScreen"}>
      {successMessage && (
        <div className="toastContainer">
          <SuccessToast
            message="Logged in successfully!"
            onClose={handleClose}
          />
        </div>
      )}
      {userType === "creator" && (
        <div className={"leftNav"}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              background: "transparent",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
                      <a href="/dashboard/"> 

            <img src={logo} width="90px" style={{ margin: 50, width: "60%" }} />
            </a>
            {/* <h2>Zeter</h2> */}
          </div>

          <ul>
            <li>
              <Link
                className={
                  screen.screen === "home" ? "navButton-selected" : "navButton"
                }
                to={"/dashboard/"}
              >
                <AiFillHome
                  className={
                    screen.screen === "home"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p>Home</p>
              </Link>
            </li>

            <li>
              {" "}
              <Link
                onClick={() => {
                  setTitle("dsf");
                }}
                className={
                  screen.screen === "order" ? "navButton-selected" : "navButton"
                }
                to={"/dashboard/order"}
              >
                <AiOutlineOrderedList
                  className={
                    screen.screen === "order"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Orders</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  screen.screen === "profile"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/profile"}
              >
                <RiAccountCircleFill
                  className={
                    screen.screen === "profile"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Profile</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  screen.screen === "payment"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/payment"}
              >
                <MdPayment
                  className={
                    screen.screen === "payment"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Payment</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  screen.screen === "telegram"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/telegram"}
              >
                <RiTelegramFill
                  className={
                    screen.screen === "telegram"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Telegram Channels</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  screen.screen === "youtube"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/youtube"}
              > 
                <FaYoutube
                  className={
                    screen.screen === "youtube"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> YouTube Channels</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  screen.screen === "tutorial"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/tutorial"}
              >
                <MdHowToReg
                  className={
                    screen.screen === "tutorial"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Tutorial</p>
              </Link>
            </li>
          </ul>
          <div className="navFooter">
            <p>{"Make what you deserve to make from your content "}</p>
          </div>
        </div>
      )}

      <div className="mobileFooter">
        <Link
          to={"/dashboard/"}
          className={ 
            screen.screen === "home" ? "iconButtons-selected" : "iconButtons"
          }
        >
          <AiFillHome />
        </Link>

        <Link
          to={"/dashboard/order"}
          className={
            screen.screen === "order" ? "iconButtons-selected" : "iconButtons"
          }
        >
          <AiOutlineOrderedList  />
        </Link>

        <Link
          to={"/dashboard/youtube"}
          className={
            screen.screen === "youtube" ? "iconButtons-selected" : "iconButtons"
          }
        >
          <RiYoutubeFill />
        </Link>

        <Link
          to={"/dashboard/telegram"}
          className={
            screen.screen === "telegram" ? "iconButtons-selected" : "iconButtons"
          }
        >
          <RiTelegramFill />
        </Link>

        <Link
          to={"/dashboard/profile"}
          className={
            screen.screen === "profile" ? "iconButtons-selected" : "iconButtons"
          }
        >
          <RiAccountCircleFill />
        </Link>
      </div>
      <div className={"headerTool"}>
        <div className="headerLeft">
          <p>{screen.nav}</p>
          <h2>{screen.headerText}</h2>
        </div>
        <div className="headerRight"> 
          <p>{bodyInfo.firstName + " " + bodyInfo.lastName}</p>
          <Link to="/dashboard/business/profile">   
          
          </Link>
          <span onClick={() => { 
          setHeadeMenuEnabled(!headerMenuEnabled);
        }}  style={{minWidth: 40, minHeight: 40, borderRadius: 20,alignItems: 'center',flexDirection:'row', display:'flex',justifyContent: 'center',margin: 5, backgroundColor: '#090d25',color:'white',border: '1px solid white'}}> {bodyInfo.firstName.charAt(0)} </span>
       
        
        
        </div>
        {headerMenuEnabled && 
        <div style={{position: 'fixed',top: 66,right: 20,zIndex:1000, width: 160, height:60,justifyContent:'center',alignItems:'center', boxShadow: '2px -2px 2px lightgray',border:'1px solid #090d25', borderRadius: 5, backgroundColor:'white'}}>
          <ul>
          
          <li onClick={()=>{
            localStorage.removeItem("token");
            localStorage.removeItem("userInfo");

            window.location.href = "https://zeterads.com/";
          }} style={{width: '80%', cursor: 'pointer',backgroundColor: '#090d25',padding: 6, borderRadius: 5, margin: 'auto',marginTop:14}}>Logout</li>

            </ul>
        </div>
        }
      </div>

      {userType === "creator" && (
        <div className={"dashboardContent"} >
          {screen.screen === "home" ? (
            <DashboardHome />
          ) : screen.screen === "order" ? (
            <Orders />
          ) : screen.screen === "order_id" ? (
            <Order id={id} />
          ) : screen.screen === "profile" ? (
            <Profile />
          ) : screen.screen === "payment" ? (
            <Payments />
          ) : screen.screen === "tutorial" ? (
            < Tutorial/>
          ) : screen.screen === "telegram" ? (
            <Telegram />
          ) : screen.screen === "youtube" ? (
            <YoutubeAccounts />
          ) : screen.screen === "addTelegramChannel" ? (
            <AddTelegramChannel />
          ) : screen.screen === "addYoutubeChannel" ? (
            <AddYoutubeChannel />
          )
          : null}
        </div>
      )}
    </div>
  );
}
