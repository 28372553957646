import { useState, useEffect } from "react";
import { getCampaignVideoDetail, getYouTubeVideoId } from "./controller.campaign";
import { useParams, useLocation } from "react-router-dom";
import TelegramEmbed from "../../dashboard/orders/video_uploader/telegram/embed_telegram";
import ChatBox from "../../../component/chat_box/chatBox";

interface CampaignOrder {
  id: number;
  creatorId: number;
  campaignId: number;
  socialMediaId: number;
  nicheId: number;
  videoUrl: string;
  viewCount: number | null;
  commentCount: number | null;
  likeCount: number | null;
}

export default function CampaignOrderDetail(order: any) {
  const [loadingVideoDetail, setLoadingVideoDetail] = useState<boolean>(false);
  const [campaignVideoDetail, setCampaignVideoDetail] = useState<any>();
  const [videoUrl, setVideoUrl] = useState<string>("");
  const param = useParams();
  const stateData = useLocation();

  useEffect(() => {
    const creatorId: number = stateData.state.creatorId;
    const campaignId: number = parseInt(param.id ?? "0");
    const socialMediaAccountId: number = parseInt(stateData.state.socialMediaAccountId ?? "0");
    console.log("orderorderorder:  ", order);

    getCampaignVideoDetail(creatorId, campaignId,socialMediaAccountId)
      .then((res: any) => {
        console.log("RRRRRRRRR: ", res);
        setCampaignVideoDetail(res);
        var embedUrl: string = getYouTubeVideoId(res?.videoUrl);
        setVideoUrl(embedUrl);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, []);

  if(campaignVideoDetail?.socialMediaId === 3){

    return(
      <div>
        <h1>Watch Your Ads here </h1> 
      <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center',padding:10,margin:10}}>
           {campaignVideoDetail.telegramPosts.map((res:any,index:number) => (
            <div style={{display:'flex',flexDirection:'column'}}>
                 <TelegramEmbed index={index} link={res.videoUrl.split("t.me/")[1]} />
            <span style={{backgroundColor:'#33AFED',padding:5,margin:10,borderRadius: 5,width:300}}>Hours On Top {res?.hoursOnTop}</span>

            </div>
           ))

           }
         
      <ChatBox campaignId={161} userId={2} />
      </div>
      </div>
    )
  }

  return (
    <div className="singleOrder">
      <div className="hoverCard">
        <>
          <h3>{campaignVideoDetail?.viewCount ?? "NA"}</h3>
          <p>Current View</p>
        </>
      </div>

      <div className="hoverCard">
        <>
          <h3>{campaignVideoDetail?.likeCount ?? "NA"}</h3>
          <p>Like Count</p>
        </>
      </div>

      <div className="hoverCard">
        <>
          <h3>{campaignVideoDetail?.commentCount ?? "NA"}</h3>
          <p>Comment Count</p>
        </>
      </div>

      <div className="singleOrder">
        {
          videoUrl?.includes("youtube") ?
          <iframe
            width="83%"
            height="615"
            src={videoUrl? videoUrl.replace("http://","https://"): ""}
            title="YouTube Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"

            allowFullScreen
          ></iframe>
          :
          <iframe
          width="83%"
          height="615"
          src={videoUrl? videoUrl.replace("http://","https://") : ""}
          title="YouTube Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"

          allowFullScreen
        ></iframe>
        }
       
      </div>
    </div>
  );
}
