import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CreatorData, getCreatorData } from "../../../admin_dashboard/creators/controller.creators";
import { AiOutlineSearch } from "react-icons/ai";

interface SearchCreatorsProps {
  setSelectedCreatorList: Dispatch<SetStateAction<any[]>>;
  socialMedia: number;
}

export function SearchCreators({ setSelectedCreatorList, socialMedia }: SearchCreatorsProps) {
  const [creatorName, setCreatorName] = useState<string>("");
  const [creator, setCreator] = useState<CreatorData[]>([]);
  const [selectedCreators, setSelectedCreators] = useState<CreatorData[]>([]);

  const handleSelectCreator = (selectedCreator: CreatorData) => {
    if (selectedCreators.some(creator => creator.socialMediaAccountId === selectedCreator.socialMediaAccountId)) {
      const updatedList = selectedCreators.filter(creator => creator.socialMediaAccountId !== selectedCreator.socialMediaAccountId);
      setSelectedCreators(updatedList);
      setSelectedCreatorList(updatedList);
    } else {
      const updatedList = [...selectedCreators, selectedCreator];
      setSelectedCreators(updatedList);
      setSelectedCreatorList(updatedList);
    }
  };

  const handleRemoveSelectedCreator = (id: number) => {
    const updatedList = selectedCreators.filter(creator => creator.socialMediaAccountId !== id);
    setSelectedCreators(updatedList);
    setSelectedCreatorList(updatedList);
  };

  useEffect(() => {
    getCreatorData(0, 10, "another", socialMedia+1).then((data: CreatorData[]) => {
      setCreator(data);
    }).catch((err) => {
      // Handle error
    });
  },[])


  // if(socialMedia === 2 && creator.length > 0){
  //   return <SocialMediaTableTelegram data={creator} />
  // }
  return (
    <div>
      <div className="flex flex-col space-y-4">
        {/* Selected Creators */}
        {selectedCreators.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {selectedCreators.map((creator) => (
              <div key={creator.id} className="bg-[#090D25] text-white px-3 py-1 rounded-full flex items-center space-x-2">
                 <img style={{width:40,height:40,borderRadius:20}} src={creator.identificationImage} alt="" />
                <span>{creator.AccountName} </span>
                <button
                  onClick={() => handleRemoveSelectedCreator(creator.socialMediaAccountId||0)}
                  className="text-blue-600 hover:text-white-800 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        )}
        <span className="bg-[#090D25] text-white px-3 py-1 rounded flex items-center space-x-1">Total Estimated Budget: {(selectedCreators.reduce((acc, creator) => acc + (parseInt(creator.AvgView.toString()) / 1000 ) * (creator?.rate || 0), 0)).toLocaleString()}</span>

        {/* Search Bar */}
        <div className="flex">
          <div className="bg-white pt-4 pb-4 w-96 rounded-md">
            <h1 className="text-lg font-bold text-gray-500 text-left">Search creator</h1>
            <div className="mt-5 mb-2 border-2 py-1 px-3 flex justify-between rounde-md rounded-md">
              <input
                value={creatorName}
                onChange={(e) => setCreatorName(e.target.value)}
                className="flex-grow outline-none text-gray-600 focus:text-blue-600"
                type="text"
                placeholder="Search creator..."
              />
              <span onClick={() => {
                getCreatorData(0, 10, creatorName, socialMedia+1).then((data: CreatorData[]) => {
                  setCreator(data);
                }).catch((err) => {
                  // Handle error
                });
              }}>
              <AiOutlineSearch size={30} />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Table */}
      <table className="min-w-full leading-normal mt-4">
        <thead>
          <tr>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Social Media Account</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Social Media</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Rate</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Avg View</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Budget</th>

            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
          </tr>
        </thead>
        <tbody>
          {creator.length > 0 && creator.map((creator) => (
            <tr key={creator.id} className={`text-left ${selectedCreators.some(sel => sel.id === creator.id) ? 'bg-blue-100' : ''}`}>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <div className="flex items-center">
                  <div className="flex-shrink-0 w-10 h-10">
                    <img className="w-full h-full rounded-full" src={creator.identificationImage} alt="" />
                  </div>
                  <div className="ml-3">
                    <p className="text-gray-900 whitespace-no-wrap">{creator.FirstName + " " + creator.LastName}</p>
                  </div>
                </div>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{creator.AccountName}</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{creator.socialMedia}</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <span className={`relative inline-block px-3 py-1 font-semibold leading-tight ${creator?.status === "true" ? 'text-green-900' : 'text-red-900'}`}>
                  <span className={`absolute inset-0 ${creator?.status === "true" ? 'bg-green-200' : 'bg-red-200'} opacity-50 rounded-full`}></span>
                  <span className="relative">{creator?.status === "true" ? "Active" : "Inactive"}</span>
                </span>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{creator.rate + " Birr / 1000 View"}</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{creator.AvgView }</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{(parseInt(creator.AvgView.toString()) / 1000 ) * (creator?.rate || 0)}</p>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <button
                  onClick={() => {
                    if (creator.status === "false" || creator?.status == null) {
                      alert("Creator is inactive");
                    } else {
                      handleSelectCreator(creator);
                    }
                  }}
                  style={{backgroundColor:'#090D25',width:100,borderRadius:5,color:'white',padding:10}}>
                  {selectedCreators.some(sel => sel.socialMediaAccountId === creator.socialMediaAccountId) ? "Deselect" : "Select"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
