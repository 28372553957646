
import { url } from "inspector";
import "./acceptedOrder.style.css";
import { useState, useEffect } from "react";

export default function CountDownTimer({ targetDates }: any) {

  
  const calculateTimeLeft = () => {
    const now: any = new Date();
    const difference = targetDates - now;
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(intervalId); 

  }, []);

  return (
    <div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',margin:0}}>
     
      <div className="countdown-timer">
        <div className="time-section">
          <div className="flip">
            <span className="time-number">{timeLeft.days}</span>
          </div>
          <span className="time-label">Days</span>
        </div>
        <div className="time-section">
          <div className="flip">
            <span className="time-number">{timeLeft.hours}</span>
          </div>
          <span className="time-label">Hours</span>
        </div>
        <div className="time-section">
          <div className="flip">
            <span className="time-number">{timeLeft.minutes}</span>
          </div>
          <span className="time-label">Minutes</span>
        </div>
        <div className="time-section">
          <div className="flip">
            <span className="time-number">{timeLeft.seconds}</span>
          </div>
          <span className="time-label">Seconds</span>
        </div>
      </div>
      
      {timeLeft.days <= 0 && timeLeft.hours <= 0 && timeLeft.minutes <= 0 && timeLeft.seconds <= 0 && (
        <h2>The countdown has ended!</h2>
      )}
    </div>

  );
}