import { useEffect, useState } from "react";
import "./VideoUploader.style.css";
import { creator_in_campaign, getNextPaymentDate, videoChecker, videoUpload } from "./videoUploader.controller";
import { Loading } from "../../../../component/loading/loading";
import { Hidden, dividerClasses } from "@mui/material";
import ShortenText from "../../../../component/shortenText/ShortenText";
import OrderStatus from "../order.status";
import ChatBox from "../../../../component/chat_box/chatBox";
import { AiOutlineCopy } from "react-icons/ai";
import TelegramEmbed from "./telegram/embed_telegram";
import CountDownTimer from "../accepted_orders/countDownTimer";

export default function VideoUploader(prop: any) {
  const [entredUrl, setEnteredUrl] = useState("");
  const [videoDetails, setVideoDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({});
  const [campaignId, setCampaignId] = useState<number>(0);
  const [creatorInA_campaign_id, setCreatorInA_campaign_id] = useState<number>(0);
  const [zeterVideoCode, setZeterVideoCode] = useState<string>("");
  const [creator_in_a_campaign, setCreator_in_a_campaign] = useState<any>({});
  const [telegram_url, setTelegram_url] = useState<any>({});
  const [refershState, setRefershState] = useState<boolean>(false);
  const [paymentInfo, setPaymentInfo] = useState<any>({});

  useEffect(() => {
    console.log("prop: ", prop.campaignData);
    async function creatorInACampaign() {
      let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "");
      console.log("userInfo: ", userInfo);
      setUserInfo(userInfo);
      let creatorInACampaign = await creator_in_campaign(prop.campaignData.campaignId, prop.campaignData.socialMediaAccountId);
      console.log("e: ", creatorInACampaign);
      setCreator_in_a_campaign(creatorInACampaign);
    }
    creatorInACampaign();

      getNextPaymentDate().then((res) => {
      console.log("res: ", res);
      
      setPaymentInfo(res);
    }).catch((err) => {
      console.log("err: ", err);
    });
    // get user id from local storage
    let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "");
    console.log("userInfo: ", userInfo);
    setZeterVideoCode("ZE" + prop.campaignData.campaignId + "TE" + prop.campaignData.id);
    setCampaignId(prop.campaignData.campaignId);
    setCreatorInA_campaign_id(prop.campaignData.id);
    setUserInfo(userInfo);
  }, [])

  useEffect(() => {
    console.log("prop: ", prop.campaignData);
    async function creatorInACampaign() {
      let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "");
      console.log("userInfo: ", userInfo);
      setUserInfo(userInfo);
      let creatorInACampaign = await creator_in_campaign(prop.campaignData.campaignId, prop.campaignData.socialMediaAccountId);
      console.log("creatorInACampaign: ", creatorInACampaign);
      setCreator_in_a_campaign(creatorInACampaign);
    }
    creatorInACampaign();
    // get user id from local storage
    let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "");
    console.log("userInfo: ", userInfo);
    setZeterVideoCode("ZE" + prop.campaignData.campaignId + "TE" + prop.campaignData.id);
    setCampaignId(prop.campaignData.campaignId);
    setCreatorInA_campaign_id(prop.campaignData.id);
    setUserInfo(userInfo);
  }, [refershState])

  return (

    <div className="videoUploaderScreen">
      <div style={{ width: '85%' }}>
        <OrderStatus status={"production"} />
        <span style={{ height: 150 }}>

        </span>
      </div>
      {
        prop.campaignData.campaign.socialMediaId == 1 ?
          <div className="videoUploaderFormNew">
            <h1>{"Zeter Code:   " + zeterVideoCode}

              <button
                onClick={() => {
                  navigator.clipboard.writeText(zeterVideoCode);
                }}
                style={{ margin: 10 }}>
                <AiOutlineCopy />
              </button>
            </h1>
            <p style={{ color: 'gray' }}>
              Please make sure to add your zeter code on your videos description, we recomand to put it at the last part of your description to avoid it bing on top
            </p>
            <input
              style={{ border: "1px solid gray" }}
              onChange={(e) => {
                setEnteredUrl(e.target.value);
              }}
              type="text"
              placeholder="Enter Video Link"
            />
            <div className="buttons">


              <button
                onClick={() => {
                  setLoading(true);
                  videoChecker(entredUrl, creatorInA_campaign_id)
                    .then((res) => {

                      console.log("res: ", res);
                      setVideoDetails(res);
                      setCheck(true);
                      setLoading(false);
                    })
                    .catch((err) => {
                      console.log("ERRR: ", err);
                    });
                }}
                className="roundButtonBorder"
              >
                {"Check"}
              </button>
            </div>

            {
              !videoDetails.passed && <>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: "space-between", marginTop: "3%", width: "100%" }}>


                  <div className="thumbnail">
                    <img
                      src={videoDetails?.thumbnail || "klj"}

                    />
                  </div>


                  <div
                    className="videoInfo"
                  >
                    <div className="innerDetail">
                      {/* <p>{"Video Title: "}</p> */}
                      <p> {videoDetails?.title || "NA"} </p>
                    </div>

                    <div className="innerDetail">
                      {/* <p>{"Video Description: "}</p> */}
                      {/* <p>  </p> */}
                      <ShortenText text={videoDetails?.description ? videoDetails?.description : "NA"} maxLength={300} />
                    </div>

                    <div className="innerDetail">
                      {/* <p>{"Video View: "}</p> */}
                      <p> {videoDetails?.View || "NA"} </p>
                    </div>
                  </div>

                </div>

                <div className="buttons">
                  <button
                    onClick={() => {
                      setLoading(true);

                      console.log("HKK: ", prop.campaignData);
                      videoUpload(prop.campaignData.campaignId, entredUrl, prop.campaignData.id, prop.campaignData.socialMediaAccountId, 1)
                        .then((res) => {
                          setLoading(false);
                          prop.acceptStatus();
                        })
                        .catch((err) => { });
                    }}
                    className="roundButtonBorder"
                  >
                    {"Submit"}
                  </button>

                </div></>}






          </div>

          :
          <div style={{ width: '70%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
              <div className="hoverCard">
                <h3>{prop.campaignData.totalPrice + " ETB"}</h3>
                <h2 style={{  borderRadius: 5 }}>Total Price</h2>
              </div>
              <div className="hoverCard">
                <h3>{prop.campaignData.days + " Days"}</h3>
                <h2 style={{  borderRadius: 5 }}>Total Days</h2>

              </div>
              <div className="hoverCard">
                {
                      (Date.now() - parseInt(creator_in_a_campaign[creator_in_a_campaign.length - 1]?.timePosted) * 1000) >= 24 * 60 * 60 * 1000 ?

                  <h3 style={{ color: 'red',textTransform:'uppercase' }}>compeleted</h3>
                  :
                <h3 style={{textTransform:'uppercase'}}>{prop.campaignData.status}</h3>

                }
                <h2>Status</h2>
              </div>
            </div>
            {prop.campaignData.days == creator_in_a_campaign.length &&
              <div className="hoverCard" style={{ width: '117%',height:270,justifyContent:'center',display:'flex',flexDirection:'column',background:'radial-gradient(circle, rgba(9,13,37,0.3449754901960784) 0%, rgba(9,13,37,1) 92%)' }}>
               {     (Date.now() - parseInt(creator_in_a_campaign[creator_in_a_campaign.length - 1]?.timePosted) * 1000) < 24 * 60 * 60 * 1000 ?

               <> 
               <h2>Campaign Will be compeleted in</h2>
               <CountDownTimer targetDates={new Date(parseInt(creator_in_a_campaign[creator_in_a_campaign.length - 1]?.timePosted) * 1000 + 24 * 60 * 60 * 1000)} />
               </>:
               <>
               {/* {paymentInfo.current_month.payment_date} */}
               <CountDownTimer targetDates={new Date(paymentInfo.current_month.payment_date)} />
               <h2>Payment Date</h2>
               </>
              }


              </div>
            }
            <div className="hoverCard" style={{ display: 'flex', width: '117%', height: '100%', overflowX: 'scroll'}}>
              {Array.from({ length: prop.campaignData.days }).map((_, index) => {

                // if(creator_in_a_campaign[index]?.videoUrl){


                // }
                return (
                  <div key={index} className="videoUploaderFormNewTelegram" style={{ boxShadow: creator_in_a_campaign[index] ? 'none' : '0px 0px 10px 0px rgba(130, 126, 126, 0.75)',margin:10 }}>
                   {!(creator_in_a_campaign[index]?.videoUrl) && 
                    <span style={{ marginTop: -50, fontSize: 24, backgroundColor: 'white', width: 100, borderRadius: 20, boxShadow: '2px 3px 4px gray' }}>
                    Day {index + 1}
                  </span>
                   }
                   
                    {creator_in_a_campaign[index]?.videoUrl &&

                      <TelegramEmbed index={index} link={creator_in_a_campaign[index]?.videoUrl.split("t.me/")[1]} />
                    }
                    <div style={{ display: 'flex', height: 100, flexDirection: "row",margin: 10 }}>
                      {/* {(index != 0 && creator_in_a_campaign[index-1]?.hoursOnTop <= 3 && !creator_in_a_campaign[index]) &&
        <h1> Upload the next AD tmorrow</h1>
        } */}
                      <input
                        style={{ border: "1px solid gray", display: creator_in_a_campaign[index]?.videoUrl ? 'none' : 'block' }}
                        onChange={(e) => {
                          //setEnteredUrl(e.target.value);
                          setTelegram_url({ ...telegram_url, [index]: e.target.value });
                        }}
                        type="text"
                        placeholder="Enter Message Link"
                      />
                      <button
                        style={{
                          background: '#090D25',
                          display: creator_in_a_campaign[index]?.videoUrl ? 'none' : 'block',
                          color: 'white',
                          borderRadius: 10,
                          width: 80,
                          height: 70,
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          videoUpload(prop.campaignData.campaignId, telegram_url[index], prop.campaignData.id, prop.campaignData.socialMediaAccountId, 3)
                            .then((res) => {
                              setLoading(false);
                              setRefershState(!refershState);
                              // prop.acceptStatus();
                            })
                            .catch((err) => {
                              console.log("error", err);
                              alert("Error " + err.message)
                            });

                        }}
                      >
                        Add
                      </button>
                    </div>
                    {creator_in_a_campaign[index] &&
                      <>


                        <div style={{borderRadius:10,overflow:'hidden'}}>
                          <h1 style={{color:'white'}}>{creator_in_a_campaign[index].hoursOnTop} Top Hours</h1>
                       
                          <h1 style={{color:'white'}}>{new Date().getHours() - new Date(parseInt(creator_in_a_campaign[index]?.timePosted) * 1000).getHours()} Total Hours</h1>
                        </div>
                      </>
                    }
                  </div>
                )
              })}

            </div>

          </div>
        // <div className="videoUploaderFormNew">
        //    <button
        //     onClick={() => {
        //       videoUpload(prop.campaignData.campaignId, "url", prop.campaignData.id,prop.campaignData.socialMediaAccountId,3)
        //       .then((res) => {
        //         setLoading(false);
        //         prop.acceptStatus();
        //       })
        //       .catch((err) => { });
        //     }}
        //    > <h1> Upload AD </h1></button>
        //   </div>
      }
      {campaignId && prop.campaignData.socialMediaAccountId &&
        <ChatBox campaignId={campaignId} userId={parseInt(prop.campaignData.socialMediaAccountId)} />

      }

    </div>





    // <div
    //   className="videoUploaderScreen"
    //   style={{ backgroundImage: `url(${prop?.campaignData?.thumbnail || ""})` }}
    // >
    //   {/* <h1>Video Uploader</h1> */}
    //   <div className="videoUploaderForm">
    //     <input
    //       onChange={(e) => {
    //         setEnteredUrl(e.target.value);
    //       }}
    //       type="text"
    //       placeholder="Enter Video Link"
    //     />
    //     <div style={{ display: "flex", flexDirection: "row" }}>
    //       <div style={{ width: 300, height: 200, overflow: "hidden" }}>
    //         <img
    //           src={videoDetails?.thumbnail || "klj"}
    //           width="400px"
    //           height="100px"
    //         />
    //       </div>
    //       <div
    //         style={{ display: "flex", flexDirection: "column", width: "65%" }}
    //       >
    //         <div className="innerDetail">
    //           <p>{"Video Title: "}</p>
    //           <p> {videoDetails?.title || "NA"} </p>
    //         </div>

    //         <div className="innerDetail">
    //           <p>{"Video Description: "}</p>
    //           <p> {videoDetails?.description} </p>
    //         </div>

    //         <div className="innerDetail">
    //           <p>{"Video View: "}</p>
    //           <p> {videoDetails?.View || "NA"} </p>
    //         </div>
    //         <div className="actionButtons">
    //           <button
    //             onClick={() => {
    //               setLoading(true);
    //               videoChecker(entredUrl)
    //                 .then((res) => {
    //                   console.log("res: ", res);
    //                   setVideoDetails(res);
    //                   setLoading(false);
    //                 })
    //                 .catch((err) => {
    //                   console.log("ERRR: ", err);
    //                 });
    //             }}
    //             className="roundButtonBorder"
    //           >
    //             {"Check"}
    //           </button>
    //           <button
    //             onClick={() => {
    //               setLoading(true);

    //               console.log("HKK: ", prop.campaignData);
    //               videoUpload(prop.campaignData.CampaignId, entredUrl)
    //                 .then((res) => {
    //                   setLoading(false);
    //                   prop.acceptStatus();
    //                 })
    //                 .catch((err) => {});
    //             }}
    //             className="roundButtonBorder"
    //           >
    //             {"Submit"}
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <Loading
    //     loading={loading}
    //     setLoading={() => {
    //       setLoading(false);
    //     }}
    //   />
    // </div>
  );
}
