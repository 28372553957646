import VideoCard from "../../../component/video/video";
import "./style.tutorial.css"

export default function Tutorial(){

    return(
        <div className="homeMain">
        {/* <div>
<h1 className="FAQheader">
          Tutorial
        </h1>
</div> */}
      <div className="FAQ">
        
  
    

          <VideoCard title="How to Create Account" url="https://www.youtube.com/embed/-g5tXDs1LEs?si=H5VDiQcdejkjWYA5" description="  Some quick example text to build on the card title and make up the bulk of the card's
                                content."/>
          <VideoCard title="How to Create Account" url="https://docs.material-tailwind.com/demo.mp4" description="  Some quick example text to build on the card title and make up the bulk of the card's
                                content."/>
          <VideoCard title="How to Create Account" url="https://docs.material-tailwind.com/demo.mp4" description="  Some quick example text to build on the card title and make up the bulk of the card's
                                content."/>
          <VideoCard title="How to Create Account" url="https://docs.material-tailwind.com/demo.mp4" description="  Some quick example text to build on the card title and make up the bulk of the card's
                                content."/>


   
      </div>
      </div>
    )
}