import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../../../component/progressBar/progressBar";

export const creatorColumnData = [
  { field: "id", headerName: "id", width: 50 },
  {
    field: "identificationImage",
    headerName: "Profile Pic",
    width: 120,
    renderCell: (params: any) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: 42,
          height: 42,
          borderRadius: 21,
        }}
      >
        <img
          src={params.value}
          alt="profile"
          style={{ width: 40, height: 40, borderRadius: 20 }}
        />
      </div>
    ),
  },
  { field: "FirstName", headerName: "Name", width: 200 },
  { field: "ExpectedView", headerName: "Expected View", width: 180 },
  { field: "viewCount", headerName: "Actual View", width: 180 },
  {
    field: "",
    headerName: "Progress",
    width: 120,
    renderCell: (params: any) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: 100,
          height: 42,
          borderRadius: 21,
        }}
      >
        <ProgressBar progress={calculateProgress(params.row.ExpectedView,params.row.viewCount)} progressBarWidth={100} progressBarHeight={20} />
      </div>
    ),
  },
  {
    field: "Status",
    headerName: "Status",
    width: 150,
    renderCell: (params: any) => (
      <Button
        disabled={params.value}
        title="Detail"
        variant="contained"
        type="button"
        color={params.value === "Requested" ? "primary" : "success"}
        size="medium"
      >
        {params.value}
      </Button>
      // <span style={{padding:10,borderRadius:5, background: params.value === 'Requested'? 'gray' : 'green',color:'white'}}>{params.value}</span>
    ),
  },
  {
    field: "Detail",
    headerName: "",
    width: 200,
    renderCell: (params: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const navigate = useNavigate();
      return (
        <Button
          disabled={(params.value.split(".")[1] == "false")}
          title="Detail"
          variant="contained"
          type="button"
          color="success"
          onClick={() => {
            console.log("params.value: ", params.row.CreatorId);
            navigate(`/dashboard/business/orderDetail/${params.row.CampaignId}`, { state: { creatorId: params.row.CreatorId  } });
          }}
        >
          Detail
        </Button>
      );
    },
  },
];


export const creatorColumnDataTelegram = [
  { field: "id", headerName: "id", width: 50 },
  {
    field: "identificationImage",
    headerName: "Profile Pic",
    width: 120,
    renderCell: (params: any) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: 42,
          height: 42,
          borderRadius: 21,
        }}
      >
        <img
          src={params.value}
          alt="profile"
          style={{ width: 40, height: 40, borderRadius: 20 }}
        />
      </div>
    ),
  },
  { field: "FirstName", headerName: "Name", width: 200 },
  // {
  //   field: "",
  //   headerName: "Progress",
  //   width: 120,
  //   renderCell: (params: any) => (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         width: 100,
  //         height: 42,
  //         borderRadius: 21,
  //       }}
  //     >
  //       <ProgressBar progress={calculateProgress(params.row.ExpectedView,params.row.viewCount)} progressBarWidth={100} progressBarHeight={20} />
  //     </div>
  //   ),
  // },
  {
    field: "Status",
    headerName: "Status",
    width: 150,
    renderCell: (params: any) => (
      <Button
        disabled={params.value}
        title="Detail"
        variant="contained"
        type="button"
        color={params.value === "Requested" ? "primary" : "success"}
        size="medium"
      >
        {params.value}
      </Button>
      // <span style={{padding:10,borderRadius:5, background: params.value === 'Requested'? 'gray' : 'green',color:'white'}}>{params.value}</span>
    ),
  },
  {
    field: "Detail",
    headerName: "",
    width: 200,
    renderCell: (params: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const navigate = useNavigate();
      return (
        <Button
          disabled={(params.value.split(".")[1] == "false")}
          title="Detail"
          variant="contained"
          type="button"
          color="success"
          onClick={() => {
            console.log("params.value: ", params.row.CreatorId);
            navigate(`/dashboard/business/orderDetail/${params.row.CampaignId}`, { state: { creatorId: params.row.CreatorId, socialMediaAccountId: params.row.socialMediaAccountId  } });
          }}
        >
          Detail
        </Button>
      );
    },
  },
];

function calculateProgress(expected: number, actual: number) {
  return parseInt(((actual / expected) * 100).toFixed(2));
}